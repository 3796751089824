// Import Components
import { Layout } from "antd";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Overview from "./components/Overview/Overview";
import SneakPeak from "./components/SneakPeak/SneakPeak";
import Plans from "./components/Plans/Plans";
import FeedBack from "./components/Feedback/Feedback";
import Contacts from "./components/Contacts/Contacts";
import FooterComponent from "./components/Footer/Footer";
import HeaderComponent from "./components/Header/Header";

// Import Styles
import "./App.css";

// Import Images
import Feature from "./components/Feature";

// Import Constants
const { Header, Footer, Content } = Layout;

function App() {
  return (
    <Layout style={{ backgroundColor: "#fff" }}>
      <Header style={headerStyle}>
        <HeaderComponent />
      </Header>
      <Content>
        <Home />
        <About />
        <Feature />
        <Overview />
        <SneakPeak />
        <Plans />
        <FeedBack />
        <Contacts />
      </Content>
      <Footer style={footerStyle}>
        <FooterComponent />
      </Footer>
    </Layout>
  );
}

// Styles

const footerStyle = {
  textAlign: "center",
  color: "#fff",
  backgroundColor: "#fff",
  padding: "0px 0px",
};

const headerStyle = {
  textAlign: "center",
  color: "#fff",
  backgroundColor: "#fff",
  padding: "0px 0px",
};

export default App;
